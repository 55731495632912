.overlayBg::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #fbba37;
  opacity: 0.75;
}
.overlayBg .MuiContainer-root {
  position: relative;
  z-index: 2;
}
